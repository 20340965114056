<template>
  <div class="row rest">
    <div class="rest aside-bg"></div>
    <WebOsTvRefSnb class="left-container" />
    <article class="right-container">
      <hgroup><h1 id="Common-info">공통 | 접속 정보</h1></hgroup>
      <table class="simple-table cell1-nowrap object">
        <tr>
          <th>API</th>
          <td>http://127.0.0.1:8950</td>
          <td>
            <ul class="list-style-none">
              <li>
                <router-link to="/docs/webos-tv/reference#tv-list"
                  >TV 리스트 조회</router-link
                >
              </li>
              <li>
                <router-link to="/docs/webos-tv/reference#tv-enroll"
                  >TV 등록</router-link
                >
              </li>
              <li>
                <router-link to="/docs/webos-tv/reference#command"
                  >Command</router-link
                >
              </li>
              <li>
                <router-link to="/docs/webos-tv/reference#control"
                  >Control</router-link
                >
              </li>
              <li>
                <router-link to="/docs/webos-tv/reference#remote"
                  >Remote</router-link
                >
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <th>WS</th>
          <td>ws://127.0.0.1:8950</td>
          <td>
            <ul class="list-style-none">
              <li>
                <router-link to="/docs/webos-tv/reference#subscribe"
                  >Subscribe</router-link
                >
              </li>
            </ul>
          </td>
        </tr>
      </table>
      <hgroup><h1>공통 | Parameter</h1></hgroup>
      <div>
        모든 webOS TV API에서 동일하게 사용되는 TV IP 파라미터입니다.
      </div>
      <table class="simple-table cell1-nowrap">
        <tr>
          <th>Name</th>
          <th>Type</th>
          <th>Description</th>
          <th>Required</th>
        </tr>
        <tr>
          <td>IP</td>
          <td>String</td>
          <td>연결할 TV IP 주소</td>
          <td>Yes</td>
        </tr>
      </table>
      <hgroup><h1>공통 | Response</h1></hgroup>
      <table class="simple-table cell1-nowrap object">
        <tr>
          <th>Error</th>
          <td style="width: 750px">
            TV가 등록되지 않은 경우 (Status : 412 Precondition Failed)
            <pre><code class="hljs"
            >{
  "error":"webOS TV is not registered"
}</code></pre>
          </td>
        </tr>
      </table>

      <hgroup><h1 id="tv-list">TV 리스트 조회</h1></hgroup>
      <h2 class="api-url">GET /webostv</h2>
      <table class="simple-table cell1-nowrap object">
        <tr>
          <th>Description</th>
          <td style="width: 800px">
            같은 네트워크에 연결된 webOS TV를 SSDP 프로토콜을 통해 조회합니다.
          </td>
        </tr>
        <tr>
          <th>Request</th>
          <td>(n/a)</td>
        </tr>
        <tr>
          <th>Response</th>
          <td>
            <pre><code class="hljs">{
    "192.168.0.41": "%5bLG%5d%20webOS%20TV%20OLED55GXKNA"
}</code></pre>
          </td>
        </tr>
      </table>
      <hgroup><h1 id="tv-enroll">TV 등록</h1></hgroup>
      <h2 class="api-url">POST /webostv/{ip}</h2>
      <table class="simple-table cell1-nowrap object">
        <tr>
          <th>Description</th>
          <td style="width: 750px">
            TV에 연결을 요청하여 TV에 연결하기 위해서 필요한 정보(client key,
            mac)를 저장합니다.
          </td>
        </tr>
        <tr>
          <th>Request</th>
          <td>＊연결할 TV의 IP 주소가 필요합니다. (TV 리스트 조회 참고)</td>
        </tr>
        <tr>
          <th>Response</th>
          <td>
            <pre><code class="hljs">{}</code></pre>
          </td>
        </tr>
      </table>
      <div>
        API 호출 후 TV 화면에 나타난 Alert 창에서 "예"를 눌러야 등록이
        완료됩니다.<br />
        기 등록된 TV의 경우 Alert 창이 나타나지 않고 바로 등록이 완료됩니다.
      </div>
      <img src="/assets/img/webs6.png" alt="" style="margin-top: 52px;" />
      <hgroup><h1 id="command">Command</h1></hgroup>
      <h2 class="api-url">POST /webostv/{ip}/command</h2>
      <table class="simple-table cell1-nowrap object">
        <tr>
          <th>Description</th>
          <td style="width: 750px">SSAP Full Payload로 TV에 요청하는 API입니다.</td>
        </tr>
        <tr>
          <th>Request</th>
          <td>＊TV의 IP 주소가 필요합니다. (<router-link to="/docs/webos-tv/reference#tv-list">TV 리스트 조회</router-link> 참고)</td>
        </tr>
        <tr>
          <th>Response</th>
          <td>
            TV가 등록된 경우
            <pre><code class="hljs">{
    //TV payload
}</code></pre>
            TV가 등록되지 않은 경우(Status : 412 Precondition Failed)
            <pre><code class="hljs">{
    "error": "webOS TV is not registered"
}</code></pre>
          </td>
        </tr>
      </table>
      <hgroup>
        <h1 id="control">Control</h1>
      </hgroup>
      <div class="api-ref-h3">
        On/Off
      </div>
      <h2 class="api-url">
        POST /webostv/{ip}/control/turn_on
      </h2>
      <table class="simple-table cell1-nowrap object">
        <tr>
          <th>Description</th>
          <td style="width: 750px">TV 전원을 켭니다.</td>
        </tr>
        <tr>
          <th>Request</th>
          <td>(n/a)</td>
        </tr>
        <tr>
          <th>Response</th>
          <td>
            <pre><code class="hljs">{}</code></pre>
          </td>
        </tr>
      </table>
      <h2 class="api-url">POST /webostv/{ip}/control/turn_off</h2>
      <table class="simple-table cell1-nowrap object">
        <tr>
          <th>Description</th>
          <td style="width: 750px">TV 전원을 끕니다.</td>
        </tr>
        <tr>
          <th>Request</th>
          <td>(n/a)</td>
        </tr>
        <tr>
          <th>Response</th>
          <td>
            <pre><code class="hljs">{}</code></pre>
          </td>
        </tr>
      </table>
      <div class="api-ref-h3">Channel</div>
      <h2 class="api-url">GET /webostv/{ip}/control/channel</h2>
      <table class="simple-table cell1-nowrap object">
        <tr>
          <th>Description</th>
          <td style="width: 750px">전체 채널 리스트를 가져옵니다.</td>
        </tr>
        <tr>
          <th>Request</th>
          <td>
            <pre><code class='hljs'>{}</code></pre>
          </td>
        </tr>
        <tr>
          <th>Response</th>
          <td>
            <pre><code class='hljs'
            >[
    {
        "channelNumber": "4-1",
        "majorNumber": 4,
        "minorNumber": 1,
        "chanCode": "UNKNOWN",
        "channelName": "",
        "physicalNumber": 4,
        "sourceIndex": 1,
        "channelType": "Terrestrial Digital TV",
        "channelTypeId": 1,
        "channelMode": "Terrestrial",
        "channelModeId": 0,
        "signalChannelId": "1_4_4_1_0_0_0",
        "descrambled": true,
        "skipped": false,
        "locked": false,
        "fineTuned": false,
        "satelliteLcn": false,
        "shortCut": 0,
        "scrambled": false,
        "serviceType": 2,
        "display": 0,
        "ONID": 0,
        "TSID": 0,
        "SVCID": 0,
        "callSign": "UNKNOWN",
        "ipChanServerUrl": "",
        "payChan": false,
        "IPChannelCode": "UNKNOWN",
        "ipCallNumber": "UNKNOWN",
        "otuFlag": false,
        "adFlag": 0,
        "HDTV": false,
        "Invisible": false,
        "DTV": true,
        "ATV": false,
        "Data": false,
        "Radio": false,
        "Numeric": false,
        "PrimaryCh": true,
        "TV": true,
        "configurationId": 0,
        "satelliteName": " ",
        "Bandwidth": 0,
        "Frequency": 69000,
        "specialService": false,
        "CASystemIDListCount": 0,
        "channelGenreCode": "",
        "channelLogoSize": "",
        "imgUrl": "",
        "imgUrl2": "",
        "favoriteIdxA": 250,
        "favoriteIdxB": 250,
        "favoriteIdxC": 250,
        "favoriteIdxD": 250,
        "favoriteIdxE": 250,
        "favoriteIdxF": 250,
        "favoriteIdxG": 250,
        "favoriteIdxH": 250,
        "waterMarkUrl": "UNKNOWN",
        "ipChanType": "UNKNOWN",
        "ipChanInteractive": false,
        "ipChanCategory": "UNKNOWN",
        "channelNameSortKey": "",
        "ipChanCpId": "UNKNOWN",
        "playerService": "com.webos.service.legacybroadcast",
        "configured": false,
        "adultFlag": 0,
        "isFreeviewPlay": 1,
        "hasBackward": 0,
        "numUnSel": false,
        "channelId": "1_4_4_1_0_0_0",
        "CASystemIDList": {},
        "programId": "1_4_4_1_0_0_0",
        "groupIdList": [
            0
        ],
        "favoriteGroup": [],
        "lastUpdated": ""
    },
    ...
]</code></pre>
          </td>
        </tr>
      </table>
      <h2 class="api-url">POST /webostv/{ip}/control/channel</h2>
      <table class="simple-table cell1-nowrap object">
        <tr>
          <th>Description</th>
          <td style="width: 750px">현재 채널을 가져옵니다.</td>
        </tr>
        <tr>
          <th>Request</th>
          <td><pre><code class='hljs'>{}</code></pre></td>
        </tr>
        <tr>
          <th>Response</th>
          <td>
            <pre><code class='hljs'>
  {
    "returnValue": true,
    "channelId": "1_15_6_1_0_0_0",
    "physicalNumber": 15,
    "isScrambled": false,
    "channelTypeName": "Terrestrial Digital TV",
    "isLocked": false,
    "dualChannel": {
        "dualChannelId": null,
        "dualChannelTypeName": null,
        "dualChannelTypeId": 255,
        "dualChannelNumber": null
    },
    "isChannelChanged": false,
    "channelModeName": "Terrestrial",
    "channelNumber": "6-1",
    "isFineTuned": false,
    "channelTypeId": 1,
    "isDescrambled": false,
    "isReplaceChannel": false,
    "isSkipped": false,
    "isHEVCChannel": false,
    "hybridtvType": null,
    "isInteractiveRestrictionChannel": false,
    "isInvisible": false,
    "favoriteGroup": null,
    "channelName": "SBS",
    "channelModeId": 0,
    "signalChannelId": "1_15_6_1_0_0_0"
}</code></pre>
          </td>
        </tr>
      </table>
      <h2 class="api-url">POST /webostv/{ip}/control/channel</h2>
      <table class="simple-table cell1-nowrap object">
        <tr>
          <th>Description</th>
          <td style="width: 750px">Channel ID로 채널을 변경합니다.</td>
        </tr>
        <tr>
          <th>Request</th>
          <td>
            <pre><code class='hljs'
            >{
    "channel_id": "1_15_6_1_0_0_0"
}</code></pre>
          </td>
        </tr>
        <tr>
          <th>Response</th>
          <td>
            <pre><code class='hljs'>{}</code></pre>
          </td>
        </tr>
      </table>
      <h2 class="api-url">POST /webostv/{ip}/control/channel/up</h2>
      <table class="simple-table cell1-nowrap object">
        <tr>
          <th>Description</th>
          <td style="width: 750px">채널을 한 칸 올립니다.</td>
        </tr>
        <tr>
          <th>Request</th>
          <td>
            <pre><code class='hljs'>{}</code></pre>
          </td>
        </tr>
        <tr>
          <th>Response</th>
          <td>
            <pre><code class='hljs'>{}</code></pre>
          </td>
        </tr>
      </table>
      <h2 class="api-url">POST /webostv/{ip}/control/channel/down</h2>
      <table class="simple-table cell1-nowrap object">
        <tr>
          <th>Description</th>
          <td style="width: 750px">채널을 한 칸 내립니다.</td>
        </tr>
        <tr>
          <th>Request</th>
          <td>
            <pre><code class='hljs'>{}</code></pre>
          </td>
        </tr>
        <tr>
          <th>Response</th>
          <td>
            <pre><code class='hljs'>{}</code></pre>
          </td>
        </tr>
      </table>
      <div class="api-ref-h3">Audio</div>
      <h2 class="api-url">GET /webostv/{ip}/control/audio</h2>
      <table class="simple-table cell1-nowrap">
        <tr>
          <th>Description</th>
          <td style="width: 750px">오디오 상태를 가져옵니다.</td>
        </tr>
        <tr>
          <th>Request</th>
          <td>(n/a)</td>
        </tr>
        <tr>
          <th>Response</th>
          <td>
            <pre><code class='hljs'
>{
    "returnValue": true,
    "volumeStatus": {
        "activeStatus": true,
        "adjustVolume": true, "maxVolume": 100, "muteStatus": false,
        "volume": 0, "mode": "normal", "soundOutput": "tv_speaker_headphone"
    },
    "callerId": "com.webos.service.apiadapter",
    "mute": false,
    "volume": 0
}</code></pre>
          </td>
        </tr>
      </table>
      <h2 class="api-url">GET /webostv/{ip}/control/audio/mute</h2>
      <table class="simple-table object cell1-nowrap">
        <tr>
          <th>Description</th>
          <td style="width: 750px">음소거 상태를 가져옵니다. (음소거O: true /음소거X: false)</td>
        </tr>
        <tr>
          <th>Request</th>
          <td>(n/a)</td>
        </tr>
        <tr>
          <th>Response</th>
          <td>
            <pre><code class='hljs'
>{
    "muted": false
}</code></pre>
          </td>
        </tr>
      </table>
      <h2 class="api-url">POST /webostv/{ip}/control/audio</h2>
      <table class="simple-table object cell1-nowrap">
        <tr>
          <th>Description</th>
          <td style="width: 750px">TV를 음소거합니다. (true: 음소거 / false: 음소거 안함)</td>
        </tr>
        <tr>
          <th>Request</th>
          <td>
            <pre><code class='hljs'
>{
    "muted": true/false
}</code></pre>
          </td>
        </tr>
        <tr>
          <th>Response</th>
          <td>
            <pre><code class='hljs'>{}</code></pre>
          </td>
        </tr>
      </table>
      <h2 class="api-url">POST /webostv/{ip}/control/audio/volume</h2>
      <table class="simple-table object cell1-nowrap">
        <tr>
          <th>Description</th>
          <td style="width: 750px">볼륨을 숫자 크기로 설정합니다.</td>
        </tr>
        <tr>
          <th>Request</th>
          <td>
            <pre><code class='hljs'
>{
    "muted": false
}</code></pre>
          </td>
        </tr>
        <tr>
          <th>Response</th>
          <td>
            <pre><code class='hljs'>{}</code></pre>
          </td>
        </tr>
      </table>
      <h2 class="api-url">GET /webostv/{ip}/control/audio/up</h2>
      <table class="simple-table object cell1-nowrap">
        <tr>
          <th>Description</th>
          <td style="width: 750px">볼륨을 한 칸 높입니다.</td>
        </tr>
        <tr>
          <th>Request</th>
          <td>
            <pre><code class='hljs'>{}</code></pre>
          </td>
        </tr>
        <tr>
          <th>Response</th>
          <td>
            <pre><code class='hljs'>{}</code></pre>
          </td>
        </tr>
      </table>
      <h2 class="api-url">GET /webostv/{ip}/control/audio/down</h2>
      <table class="simple-table object cell1-nowrap">
        <tr>
          <th>Description</th>
          <td style="width: 750px">볼륨을 한 칸 낮춥니다.</td>
        </tr>
        <tr>
          <th>Request</th>
          <td>
            <pre><code class='hljs'>{}</code></pre>
          </td>
        </tr>
        <tr>
          <th>Response</th>
          <td>
            <pre><code class='hljs'>{}</code></pre>
          </td>
        </tr>
      </table>
      <div class="api-ref-h3">Apps</div>
      <h2 class="api-url">GET /webostv/{ip}/control/app</h2>
      <table class="simple-table object cell1-nowrap">
        <tr>
          <th>Description</th>
          <td style="width: 750px">현재 실행 중인 앱을 가져옵니다.</td>
        </tr>
        <tr>
          <th>Request</th>
          <td>(n/a)</td>
        </tr>
        <tr>
          <th>Response</th>
          <td>
            <pre><code class='hljs'
>{
    "name": "com.webos.app.livetv"
}</code></pre>
          </td>
        </tr>
      </table>
      <h2 class="api-url">POST /webostv/{ip}/control/app</h2>
      <table class="simple-table object cell1-nowrap">
        <tr>
          <th>Description</th>
          <td style="width: 750px">앱 이름으로 앱을 실행합니다.</td>
        </tr>
        <tr>
          <th>Request</th>
          <td>
            <pre><code class='hljs'
>{
    "name": "com.webos.app.livetv"
}</code></pre>
          </td>
        </tr>
        <tr>
          <th>Response</th>
          <td>
            <pre><code class='hljs'>{}</code></pre>
          </td>
        </tr>
      </table>
      <h2 class="api-url">DELETE /webostv/{ip}/control/app</h2>
      <table class="simple-table object cell1-nowrap">
        <tr>
          <th>Description</th>
          <td style="width: 750px">앱 이름으로 앱을 종료합니다.</td>
        </tr>
        <tr>
          <th>Request</th>
          <td>
            <pre><code class='hljs'
>{
    "name": "com.webos.app.livetv"
}</code></pre>
          </td>
        </tr>
        <tr>
          <th>Response</th>
          <td>
            <pre><code class='hljs'
>{}</code></pre>
          </td>
        </tr>
      </table>
      <div class="api-ref-h3" id="ui">UI 및 기타</div>
      <h2 class="api-url">POST /webostv/{ip}/control/toast</h2>
      <table class="simple-table object cell1-nowrap">
        <tr>
          <th>Description</th>
          <td style="width: 750px">TV 화면에 Toast 메시지를 띄웁니다.</td>
        </tr>
        <tr>
          <th>Request</th>
          <td>
            <pre><code class='hljs'
>{
    "message": "안녕하세요!!"
}</code></pre>
          </td>
        </tr>
        <tr>
          <th>Response</th>
          <td>
            <pre><code class='hljs'>{}</code></pre>
          </td>
        </tr>
      </table>
      <h2 class="api-url">POST /webostv/{ip}/control/alert/youtube</h2>
      <table class="simple-table object cell1-nowrap">
        <tr>
          <th>Description</th>
          <td style="width: 750px">Alert 창을 띄워 유튜브 영상을 실행합니다.</td>
        </tr>
        <tr>
          <th>Request</th>
          <td>
            <pre><code class='hljs'>{
    "title": "[유튜브] 골드 피즈의 평범한 하루",
    "message": "추천영상입니다. 유튜브 앱에서 시청할까요?",
    "code": "v=96afkL4NXqI"
}</code></pre>
          </td>
        </tr>
        <tr>
          <th>Response</th>
          <td>
            <pre><code class='hljs'>{}</code></pre>
          </td>
        </tr>
      </table>
      <h2 class="api-url" id="capture">GET /webostv/{ip}/control/capture</h2>
      <table class="simple-table object cell1-nowrap">
        <tr>
          <th>Description</th>
          <td style="width: 750px">TV 화면을 캡쳐합니다.</td>
        </tr>
        <tr>
          <th>Request</th>
          <td>
            <pre><code class='hljs'>{}</code></pre>
          </td>
        </tr>
        <tr>
          <th>Response</th>
          <td>
            <pre><code class='hljs'
>{
    "returnValue": true,
    "imageUri": "http://192.168.0.41:3000/resources/dbdd8e71478ebdbcac751da54811a185848fed0e/capture.jpg"
}</code></pre>
          </td>
        </tr>
      </table>
      <h2 class="api-url">POST /webostv/{ip}/control/web</h2>
      <table class="simple-table object cell1-nowrap">
        <tr>
          <th>Description</th>
          <td style="width: 750px">TV에서 웹브라우저로 웹사이트를 엽니다.</td>
        </tr>
        <tr>
          <th>Request</th>
          <td>
            <pre><code class='hljs'>{
    "url": "https://www.lge.co.kr/"
}</code></pre>
          </td>
        </tr>
        <tr>
          <th>Response</th>
          <td>
            <pre><code class='hljs'>{}</code></pre>
          </td>
        </tr>
      </table>
      <hgroup id="remote"><h1>Remote</h1></hgroup>
      <h2 class="api-url">
        POST /webostv/{ip}/remote/{home, back, ok, exit, up, down, left, right}
      </h2>
      <table class="simple-table object cell1-nowrap">
        <tr>
          <th>Description</th>
          <td style="width: 750px">리모컨의 기능별 버튼을 누릅니다.</td>
        </tr>
        <tr>
          <th>Request</th>
          <td>(n/a)</td>
        </tr>
        <tr>
          <th>Response</th>
          <td>
            <pre><code class='hljs'>{}</code></pre>
          </td>
        </tr>
      </table>
      <h2 class="api-url">POST /webostv/{ip}/remote/number</h2>
      <table class="simple-table object cell1-nowrap">
        <tr>
          <th>Description</th>
          <td style="width: 750px">리모컨의 숫자 버튼을 누릅니다.</td>
        </tr>
        <tr>
          <th>Request</th>
          <td>
            <pre><code class='hljs'>{
    "number": 1
}</code></pre>
          </td>
        </tr>
        <tr>
          <th>Response</th>
          <td>
            <pre><code class='hljs'>{}</code></pre>
          </td>
        </tr>
      </table>
      <hgroup id="subscribe"><h1>Subscribe</h1></hgroup>
      <h2 class="api-url">ws://{host}/webostv/{ip}/subscribe</h2>
      <table class="simple-table object cell1-nowrap">
        <tr>
          <th>Description</th>
          <td style="width: 750px">Topic 1) 채널을 구독합니다.</td>
        </tr>
        <tr>
          <th>Message</th>
          <td>Channel</td>
        </tr>
        <tr>
          <th>Response</th>
          <td>
            <pre><code class='hljs'>{
    "type":"response",
    "id":"1",
    "payload":{
        "programId":"R109814620",
        "programName":"뉴스브리핑",
        "description":"",
        "startTime":"2021,08,11,04,53,01",
        "endTime":"2021,08,11,06,52,01",
        "localStartTime":"2021,08,11,13,53,01",
        "localEndTime":"2021,08,11,15,52,01",
        "duration":7140,
        "channelId":"1_15_6_1_0_0_0",
        "channelName":"SBS",
        "channelNumber":"6-1",
        "channelMode":"Terrestrial",
        "subscribed":true
    }
}</code></pre>
          </td>
        </tr>
      </table>
      <h2 class="api-url">ws://{host}/webostv/{ip}/subscribe</h2>
      <table class="simple-table object cell1-nowrap">
        <tr>
          <th>Description</th>
          <td style="width: 750px">Topic 2) 오디오를 구독합니다.</td>
        </tr>
        <tr>
          <th>Message</th>
          <td>Audio</td>
        </tr>
        <tr>
          <th>Response</th>
          <td>
            <pre><code class='hljs'>{
    "type":"response",
    "id":"1",
    "payload":{
        "volumeStatus":{
            "activeStatus":true,
            "adjustVolume":true,
            "maxVolume":100,
            "muteStatus":false,
            "volume":0,
            "mode":"normal",
            "soundOutput":"tv_speaker_headphone"
        },
        "returnValue":true,
        "callerId":"secondscreen.client"
    }
}</code></pre>
          </td>
        </tr>
      </table>
    </article>
    <div class="rest"></div>
  </div>
</template>
<script>
import CodeViewer from "@/components/CodeViewer.vue";
import webostverrorresjson from "@/assets/code/webostv-error-res.json";
import tvlistresjson from "@/assets/code/tv-list-res.json";
import tvaddresjson from "@/assets/code/tv-add-res.json";
import WebOsTvRefSnb from "./WebOsTvRefSnb.vue";

export default {
  data() {
    return {
      webostverrorresjson: JSON.stringify(webostverrorresjson),
      tvlistresjson: JSON.stringify(tvlistresjson),
      tvaddresjson: JSON.stringify(tvaddresjson),
    };
  },
  components: {
    CodeViewer,
    WebOsTvRefSnb,
  },
};
</script>
